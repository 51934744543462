<template>
  <div>
    <header-label
      :label="$t('search.header')"
      prepend-icon="mdi-magnify" />
    <v-card
      class="app-card"
      flat>
      <v-card-text>
        <div class="d-flex justify-space-between mb-3">
          <title-label :label="title" />
          <v-spacer />
          <div>
            <v-text-field
              v-model="query"
              :placeholder="$t('search.search.placeholder')"
              append-icon="mdi-magnify"
              autofocus
              hide-details
              outlined
              dense
              @keydown.enter="search()"
              @click:append="search()"
            />
          </div>
        </div>
        <div>
          <ul class="spotlight-result">
            <li
              v-for="(item, index) in items"
              :key="index">
              <v-btn
                :to="item.to"
                text
                class="app-link text-subtitle-1 text-decoration-none primary--text font-weight-medium d-inline-block"
              >
                {{ item.keyword }} - {{ item.name }}
              </v-btn>
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'Search',
  data: () => ({
    query: '',
    dataSource: [],
    items: []
  }),
  computed: {
    ...mapGetters({}),
    title () {
      const str = this.items.length
        ? `${this.$t('search.search.found')} ${this.$route.query.q}`
        : this.$t('search.search.notfound')
      return str
    }
  },
  mounted () {
    this.query = this.$route.query.q || ''
    this.fetch(this.query)
  },
  methods: {
    ...mapActions({}),
    async fetch (q) {
      this.items = []
      const pages = [
        ['shopinfo', '/shopinfo'],
        ['shopsetting', '/shop-setting'],
        ['language', '/shop-setting/language'],
        ['order', '/shop-setting/order'],
        ['crm', '/shop-setting/crm'],
        ['packing', '/shop-setting/packing'],
        ['payment', '/shop-setting/payment'],
        ['product', '/shop-setting/product'],
        ['promocode', '/shop-setting/promocode'],
        ['taxinvoice', '/shop-setting/taxinvoice'],
        ['warehouse', '/shop-setting/warehouse'],
        ['shipping', '/shipping'],
        ['receipt', '/receipt'],
        ['message', '/message'],
        ['linkbill', '/link-bill'],
        ['connector', '/connector'],
        ['salepage', '/sale-page'],
        ['agent', '/agent'],
        ['administrator', '/administrator'],
        ['account', '/account']
      ]

      const promises = pages.map((page) => axios.get(`${process.env.VUE_APP_ROUTE_PREFIX}locales/th/${page[0]}.json`))
      const locales = await Promise.all(promises)
      pages.forEach((page, i) => {
        const locale = locales[i].data
        for (let j = 0; j < locale.keywords.length; j++) {
          const score = this.stringScore(locale.keywords[j], q, 1)
          if (score > 0.56) {
            this.items.push({
              name: locale.header,
              to: page[1],
              keyword: locale.keywords[j],
              score
            })
          }
        }
      })
      this.items = this.items.sort((a, b) => a.score - b.score).reverse()
    },
    search () {
      if (this.query) {
        this.$router.push(`/search?q=${this.query}`)
        this.fetch(this.query)
      }
    },
    stringScore (conversation, word, fuzziness) {
      // If the string is equal to the word, perfect match.
      if (conversation === word) {
        return 1
      }

      // if it's not a perfect match and is empty return 0
      if (word === '') {
        return 0
      }

      let runningScore = 0
      let charScore
      let finalScore
      const string = conversation
      const lString = string.toLowerCase()
      const strLength = string.length
      const lWord = word.toLowerCase()
      const wordLength = word.length
      let idxOf
      let startAt = 0
      let fuzzies = 1
      let fuzzyFactor
      let i

      // Cache fuzzyFactor for speed increase
      if (fuzziness) {
        fuzzyFactor = 1 - fuzziness
      }

      // Walk through word and add up scores.
      // Code duplication occurs to prevent checking fuzziness inside for loop
      if (fuzziness) {
        for (i = 0; i < wordLength; i += 1) {
          // Find next first case-insensitive match of a character.
          idxOf = lString.indexOf(lWord[i], startAt)

          if (idxOf === -1) {
            fuzzies += fuzzyFactor
          } else {
            if (startAt === idxOf) {
              // Consecutive letter & start-of-string Bonus
              charScore = 0.7
            } else {
              charScore = 0.1

              // Acronym Bonus
              // Weighing Logic: Typing the first character of an acronym is as if you
              // preceded it with two perfect character matches.
              if (string[idxOf - 1] === ' ') {
                charScore += 0.8
              }
            }

            // Same case bonus.
            if (string[idxOf] === word[i]) {
              charScore += 0.1
            }

            // Update scores and startAt position for next round of indexOf
            runningScore += charScore
            startAt = idxOf + 1
          }
        }
      } else {
        for (i = 0; i < wordLength; i += 1) {
          idxOf = lString.indexOf(lWord[i], startAt)
          if (idxOf === -1) {
            return 0
          }

          if (startAt === idxOf) {
            charScore = 0.7
          } else {
            charScore = 0.1
            if (string[idxOf - 1] === ' ') {
              charScore += 0.8
            }
          }
          if (string[idxOf] === word[i]) {
            charScore += 0.1
          }
          runningScore += charScore
          startAt = idxOf + 1
        }
      }

      // Reduce penalty for longer strings.
      finalScore = (0.5 * (runningScore / strLength + runningScore / wordLength))
        / fuzzies

      if (lWord[0] === lString[0] && finalScore < 0.85) {
        finalScore += 0.15
      }

      return finalScore
    }
  }
}
</script>

<style>
</style>

<style scoped>
ul.spotlight-result {
  list-style-type: none;
  padding: 0;
}

ul.spotlight-result > li {
  margin-bottom: 10px;
}
.app-link {
  padding: 0 !important;
  height: auto !important;
}
.app-link.v-btn:hover::before,
.app-link.v-btn:focus::before{
  background-color: transparent !important;
}
</style>
