var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: { label: _vm.$t("search.header"), "prepend-icon": "mdi-magnify" }
      }),
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { flat: "" } },
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex justify-space-between mb-3" },
              [
                _c("title-label", { attrs: { label: _vm.title } }),
                _c("v-spacer"),
                _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        placeholder: _vm.$t("search.search.placeholder"),
                        "append-icon": "mdi-magnify",
                        autofocus: "",
                        "hide-details": "",
                        outlined: "",
                        dense: ""
                      },
                      on: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.search()
                        },
                        "click:append": function($event) {
                          return _vm.search()
                        }
                      },
                      model: {
                        value: _vm.query,
                        callback: function($$v) {
                          _vm.query = $$v
                        },
                        expression: "query"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", [
              _c(
                "ul",
                { staticClass: "spotlight-result" },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "app-link text-subtitle-1 text-decoration-none primary--text font-weight-medium d-inline-block",
                          attrs: { to: item.to, text: "" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.keyword) +
                              " - " +
                              _vm._s(item.name) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }